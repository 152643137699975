:root {
  --accent: #FFA000;
  --action_sheet_action_foreground: #FFA000;
  --action_sheet_separator: rgba(0, 0, 0, 0.12);
  --activity_indicator_tint: #aeb7c2;
  --attach_picker_tab_active_background: #FFA000;
  --attach_picker_tab_active_icon: #ffffff;
  --attach_picker_tab_active_text: #FFA000;
  --attach_picker_tab_inactive_background: #f2f3f5;
  --attach_picker_tab_inactive_icon: #818c99;
  --attach_picker_tab_inactive_text: #818c99;
  --background_content: #ffffff;
  --background_highlighted: rgba(0, 0, 0, 0.08);
  --background_hover: rgba(0, 0, 0, 0.04);
  --background_keyboard: #e1e3e6;
  --background_light: #f9f9f9;
  --background_page: #ebedf0;
  --background_suggestions: #ffffff;
  --background_text_highlighted: rgba(63, 138, 224, 0.20);
  --button_bot_shadow: #c4c8cc;
  --button_commerce_background: #4bb34b;
  --button_commerce_foreground: #ffffff;
  --button_muted_background: #f2f3f5;
  --button_muted_foreground: #FFA000;
  --button_outline_border: #FFA000;
  --button_outline_foreground: #FFA000;
  --button_primary_background: #FFA000;
  --button_primary_foreground: #ffffff;
  --button_secondary_background: rgba(0, 28, 61, 0.05);
  --button_secondary_background_highlighted: rgba(0, 28, 61, 0.03);
  --button_secondary_destructive_background: rgba(0, 28, 61, 0.05);
  --button_secondary_destructive_background_highlighted: rgba(0, 28, 61, 0.03);
  --button_secondary_destructive_foreground: #e64646;
  --button_secondary_destructive_foreground_highlighted: rgba(230, 70, 70, 0.60);
  --button_secondary_foreground: #FFA000;
  --button_secondary_foreground_highlighted: rgba(63, 138, 224, 0.60);
  --button_tertiary_background: rgba(0, 0, 0, 0.00);
  --button_tertiary_foreground: #FFA000;
  --cell_button_foreground: #FFA000;
  --content_placeholder_icon: #99a2ad;
  --content_placeholder_text: #818c99;
  --content_tint_background: #f5f5f5;
  --content_tint_foreground: #7f8285;
  --content_warning_background: #fff2d6;
  --control_background: #ebedf0;
  --control_foreground: #5181b8;
  --control_tint: #ffffff;
  --control_tint_muted: #76787a;
  --counter_primary_background: #FFA000;
  --counter_primary_text: #ffffff;
  --counter_prominent_background: #ff3347;
  --counter_prominent_text: #ffffff;
  --counter_secondary_background: #aeb7c2;
  --counter_secondary_text: #ffffff;
  --destructive: #e64646;
  --dynamic_blue: #FFA000;
  --dynamic_gray: #a3adb8;
  --dynamic_green: #4bb34b;
  --dynamic_orange: #ffa000;
  --dynamic_purple: #735ce6;
  --dynamic_red: #ff3347;
  --dynamic_violet: #792ec0;
  --feed_recommended_friend_promo_background: #528bcc;
  --field_background: #f2f3f5;
  --field_border: rgba(0, 0, 0, 0.12);
  --field_error_background: #faebeb;
  --field_error_border: #e64646;
  --field_text_placeholder: #818c99;
  --field_valid_border: #4bb34b;
  --float_button_background: #f5f5f5;
  --float_button_background_highlighted: #ebedf0;
  --float_button_border: rgba(0, 0, 0, 0.05);
  --float_button_foreground: #99a2ad;
  --header_alternate_background: #ffffff;
  --header_alternate_tab_active_indicator: #FFA000;
  --header_alternate_tab_active_text: #000000;
  --header_alternate_tab_inactive_text: #99a2ad;
  --header_background: #ffffff;
  --header_background_before_blur: #ffffff;
  --header_background_before_blur_alternate: #ffffff;
  --header_search_field_background: #ebedf0;
  --header_search_field_tint: #818c99;
  --header_tab_active_background: rgba(0, 0, 0, 0.00);
  --header_tab_active_indicator: #FFA000;
  --header_tab_active_text: #000000;
  --header_tab_inactive_text: #99a2ad;
  --header_text: #000000;
  --header_text_alternate: #000000;
  --header_text_secondary: #818c99;
  --header_tint: #FFA000;
  --header_tint_alternate: #FFA000;
  --icon_alpha_placeholder: #ffffff;
  --icon_medium: #818c99;
  --icon_medium_alpha: rgba(0, 0, 0, 0.48);
  --icon_name: #74a2d6;
  --icon_outline_medium: #818c99;
  --icon_outline_secondary: #99a2ad;
  --icon_secondary: #99a2ad;
  --icon_secondary_alpha: rgba(0, 0, 0, 0.36);
  --icon_tertiary: #b8c1cc;
  --icon_tertiary_alpha: rgba(0, 0, 0, 0.24);
  --im_attach_tint: #FFA000;
  --im_bubble_border_alternate: #e1e3e6;
  --im_bubble_border_alternate_highlighted: #d7d8d9;
  --im_bubble_button_background: #f9f9f9;
  --im_bubble_button_background_highlighted: #d7d8d9;
  --im_bubble_button_foreground: #000000;
  --im_bubble_button_outgoing_background: rgba(255, 255, 255, 0.60);
  --im_bubble_gift_background: #f4e7c3;
  --im_bubble_gift_background_highlighted: #e3d3ac;
  --im_bubble_gift_text: #857250;
  --im_bubble_gift_text_secondary: #ab9871;
  --im_bubble_incoming: #ebedf0;
  --im_bubble_incoming_alternate: #ffffff;
  --im_bubble_incoming_alternate_highlighted: #f9f9f9;
  --im_bubble_incoming_expiring: #e6e9ff;
  --im_bubble_incoming_expiring_highlighted: #ccd3ff;
  --im_bubble_incoming_highlighted: #d7d8d9;
  --im_bubble_outgoing: #cce4ff;
  --im_bubble_outgoing_alternate: #ffffff;
  --im_bubble_outgoing_alternate_highlighted: #f9f9f9;
  --im_bubble_outgoing_expiring: #ccd3ff;
  --im_bubble_outgoing_expiring_highlighted: #adb8ff;
  --im_bubble_outgoing_highlighted: #add3ff;
  --im_bubble_wallpaper_button_background: #f2f3f5;
  --im_bubble_wallpaper_button_background_highlighted: #e1e3e6;
  --im_bubble_wallpaper_button_foreground: #000000;
  --im_bubble_wallpaper_incoming: #ffffff;
  --im_bubble_wallpaper_incoming_highlighted: #ebedf0;
  --im_bubble_wallpaper_outgoing: #cce4ff;
  --im_bubble_wallpaper_outgoing_highlighted: #add3ff;
  --im_forward_line_tint: #45678f;
  --im_reply_separator: #FFA000;
  --im_service_message_text: #818c99;
  --im_text_name: #FFA000;
  --im_toolbar_separator: #001c3d;
  --im_toolbar_voice_msg_background: #99a2ad;
  --image_border: rgba(0, 0, 0, 0.08);
  --input_background: #f2f3f5;
  --input_border: #e1e3e6;
  --landing_background: #ffffff;
  --landing_field_background: #f2f3f5;
  --landing_field_border: rgba(0, 0, 0, 0.00);
  --landing_field_placeholder: #818c99;
  --landing_login_button_background: #FFA000;
  --landing_login_button_foreground: #ffffff;
  --landing_primary_button_background: #FFA000;
  --landing_primary_button_foreground: #ffffff;
  --landing_secondary_button_background: rgba(0, 57, 115, 0.10);
  --landing_secondary_button_foreground: #FFA000;
  --landing_snippet_border: rgba(0, 0, 0, 0.15);
  --landing_tertiary_button_background: rgba(0, 0, 0, 0.00);
  --landing_tertiary_button_foreground: #FFA000;
  --landing_text_primary: #000000;
  --landing_text_secondary: #818c99;
  --landing_text_title: #FFA000;
  --like_text_tint: #ff3347;
  --link_alternate: #FFA000;
  --loader_background: #ebedf0;
  --loader_tint: #76787a;
  --loader_track_fill: #e1e3e6;
  --loader_track_value_fill: #528bcc;
  --media_overlay_button_background: #ffffff;
  --media_overlay_button_foreground: #2c2d2e;
  --modal_card_background: #ffffff;
  --modal_card_border: rgba(0, 0, 0, 0.00);
  --modal_card_header_close: #000000;
  --music_playback_icon: #000000;
  --overlay_status_background: #ebedf0;
  --overlay_status_foreground: #6d7885;
  --overlay_status_icon: #818c99;
  --panel_tab_active_background: #ebedf0;
  --panel_tab_active_text: #5d5f61;
  --panel_tab_inactive_text: #76787a;
  --placeholder_icon_background: rgba(0, 28, 61, 0.08);
  --placeholder_icon_background_opaque: #ebedf0;
  --placeholder_icon_foreground_primary: #99a2ad;
  --placeholder_icon_foreground_secondary: #aeb7c2;
  --placeholder_icon_tint: #001c3d;
  --poll_option_background: #45678f;
  --search_bar_background: #ffffff;
  --search_bar_field_background: #ebedf0;
  --search_bar_field_tint: #818c99;
  --search_bar_segmented_control_tint: #818c99;
  --segmented_control_bar_background: rgba(0, 0, 0, 0.00);
  --segmented_control_tint: #99a2ad;
  --selection_off_icon: #b8c1cc;
  --separator_alpha: rgba(0, 0, 0, 0.12);
  --separator_alternate: #e1e3e6;
  --separator_common: #d7d8d9;
  --skeleton_shimmer_from: #ffffff;
  --skeleton_shimmer_to: #ffffff;
  --snippet_background: #ffffff;
  --snippet_border: rgba(0, 0, 0, 0.15);
  --snippet_icon_tertiary: #c4c8cc;
  --splashscreen_icon: #e1e3e6;
  --statusbar_alternate_legacy_background: rgba(0, 0, 0, 0.20);
  --stories_create_button_background_from: #f2f3f5;
  --stories_create_button_background_to: #e1e3e6;
  --stories_create_button_foreground: #6d7885;
  --stories_create_button_icon_background: #ffffff;
  --stories_skeleton_loader_background: #c4c8cc;
  --switch_ios_off_border: #e1e3e6;
  --tabbar_active_icon: #FFA000;
  --tabbar_background: #f9f9f9;
  --tabbar_inactive_icon: #99a2ad;
  --tabbar_tablet_active_icon: #FFA000;
  --tabbar_tablet_background: #f9f9f9;
  --tabbar_tablet_inactive_icon: #99a2ad;
  --tabbar_tablet_text_primary: #000000;
  --tabbar_tablet_text_secondary: #909499;
  --text_action_counter: #818c99;
  --text_link: #FFA000;
  --text_link_highlighted_background: #000000;
  --text_muted: #2c2d2e;
  --text_name: #FFA000;
  --text_placeholder: #818c99;
  --text_primary: #000000;
  --text_secondary: #818c99;
  --text_subhead: #6d7885;
  --text_tertiary: #99a2ad;
  --toolbar_attach_background_from: #b8c1cc;
  --toolbar_attach_background_to: #a3adb8;
  --vk_connect_button_primary_background: #19191a;
  --vk_connect_button_primary_foreground: #ffffff;
  --vk_connect_button_secondary_background: #f2f3f5;
  --vk_connect_button_secondary_foreground: #19191a;
  --writebar_icon: #FFA000;
}
